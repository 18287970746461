<template>
    <div id="administrator-dashboard">
        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-card no-body>

                <!-- <b-col md="6">
                    <b-form-group>
                        <h5>Disabled Range</h5>
                        <flat-pickr v-model="rangePicker" class="form-control"
                            :config="{ dateFormat: 'Y-m-d', disable: [{ from: '2020-08-20', to: '2020-08-25' }] }" />
                    </b-form-group>
                </b-col> -->
                <b-card-title class="mt-2">

                    <b-row class="justify-content-md-center">

                        <b-col cols="12" md="auto">
                            <div class="d-flex justify-content-center align-items-center">
                                <feather-icon icon="CalendarIcon" size="30" />
                                <flat-pickr v-model="rangePicker" :config="{ mode: 'range' }"
                                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                                    style="width: 150%;font-size: 20px;" placeholder="YYYY-MM-DD" />
                            </div>
                        </b-col>
                    </b-row>

                </b-card-title>

                <b-card-body>
                    <section id="dashboard-admin" :key="showDataKeys">
                        <b-row class="match-height">
                            <b-col lg="6" sm="12">
                                <b-card no-body class="card-revenue-budget">
                                    <b-card-header>
                                        <b-col lg="12" sm="12">
                                            <h3>Classement des Clients : Les 10 Meilleurs en Termes de Nombre de Commandes
                                            </h3>
                                        </b-col>
                                    </b-card-header>
                                    <vue-apex-charts height="400" :options="pieChartNumbers"
                                        :series="pieChartNumbers.series"></vue-apex-charts>
                                </b-card>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <b-card no-body class="card-revenue-budget">
                                    <b-card-header>
                                        <b-col lg="12" sm="12">
                                            <h3>Classement des Clients : Les 10 Meilleurs en Termes de Chiffre d'Affaires
                                            </h3>
                                        </b-col>
                                    </b-card-header>
                                    <vue-apex-charts height="400" :options="pieChartAmount"
                                        :series="pieChartAmount.series"></vue-apex-charts>
                                </b-card>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <b-card no-body class="card-revenue-budget">
                                    <b-card-header>
                                        <b-col lg="12" sm="12">
                                            <h3>{{ culumnChartNumbersTitle }}</h3>
                                        </b-col>
                                    </b-card-header>
                                    <vue-apex-charts type="line" height="400" :options="columnChartNumbers.chartOptions"
                                        :series="columnChartNumbers.series"></vue-apex-charts>
                                </b-card>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <b-card no-body class="card-revenue-budget">
                                    <b-card-header>
                                        <b-col lg="12" sm="12">
                                            <h3>{{ columnChartAmountTitle }}</h3>
                                        </b-col>
                                    </b-card-header>
                                    <vue-apex-charts type="bar" height="400" :options="columnChartAmount.chartOptions"
                                        :series="columnChartAmount.series"></vue-apex-charts>
                                </b-card>
                            </b-col>
                            <!-- <b-col lg="12" sm="12">
                                <b-card no-body class="card-revenue-budget" >
                                    <b-card-header>
                                        <b-col lg="12" sm="12">
                                            <h3>Variation Quotidienne des Ventes Par Chiffre D'affaires</h3>
                                        </b-col>
                                    </b-card-header>
                                    <vue-apex-charts  type="area" height="400"
                                        :options="columnChartAmount.chartOptions"
                                        :series="columnChartAmount.series"></vue-apex-charts>
                                </b-card>
                            </b-col> -->
                            <!-- <b-col lg="12" sm="12">
                                <b-card no-body class="card-revenue-budget" >
                                    <b-card-header>
                                        <b-col lg="12" sm="12">
                                            <h3>Variation Quotidienne des Ventes Par Chiffre D'affaires</h3>
                                        </b-col>
                                    </b-card-header>
                                    <vue-apex-charts  type="heatmap"
                                        height="400" :options="columnChartAmount.chartOptions"
                                        :series="columnChartAmount.series"></vue-apex-charts>
                                </b-card>
                            </b-col>  -->
                        </b-row>
                    </section>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
    kFormatter
} from '@core/utils/filter'
import {
    $themeColors
} from "@themeConfig";
import VueApexCharts from "vue-apexcharts";
export default {
    components: {
        VueApexCharts,
        flatPickr
    },
    data() {
        return {
            culumnChartNumbersTitle: "Variation Quotidienne des Ventes Par Nombre de Commandes",
            columnChartAmountTitle: "Variation Quotidienne des Ventes Par Chiffre D'affaires",
            rangePicker: ['2019-05-01', '2019-05-10'],
            columnChartNumbers: {
                series: [{
                    name: 'Nombre Total',
                    data: []
                },
                {
                    name: 'Nombre Total N-1',
                    data: []
                }],
                chartOptions: {
                    colors: [$themeColors.primary, $themeColors.secondary],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded',
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    // stroke: {
                    //     show: true,
                    //     width: 2,
                    //     colors: ['transparent']
                    //     // show: false,
                    //     // curve: 'smooth',
                    // },
                    // chart: {
                    //     height: 350,
                    //     type: 'line',
                    // },
                    // forecastDataPoints: {
                    //     count: 7
                    // },
                    stroke: {
                        width: 5,
                        curve: 'smooth',
                        show: true,
                    },

                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return kFormatter(value);
                            }
                        },
                    },
                    // fill: {
                    //     opacity: 1
                    // },
                    // fill: {
                    //     type: 'gradient',
                    //     gradient: {
                    //         shade: 'dark',
                    //         gradientToColors: [$themeColors.primary, $themeColors.secondary],
                    //         shadeIntensity: 1,
                    //         type: 'horizontal',
                    //         opacityFrom: 1,
                    //         opacityTo: 1,
                    //         stops: [0, 100, 100, 100]
                    //     },
                    // },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            }
                        }
                    }
                }
            },
            columnChartAmount: {
                series: [{
                    name: 'Montant Total',
                    data: []
                },
                {
                    name: 'Montant Total N-1',
                    data: []
                }],
                chartOptions: {
                    colors: [$themeColors.success, $themeColors.secondary],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded',
                            columnWidth: '45%',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },

                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return kFormatter(value);
                            }
                        },
                    },
                    fill: {
                        opacity: 1

                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            }
                        }
                    }
                }
            },
            pieChartNumbers: {
                series: [],
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return kFormatter(val);
                        }
                    }
                }
            },
            pieChartAmount: {
                series: [],
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return kFormatter(val);
                        }
                    }
                }
            },
            showDataKeys: 0,
            showLoading: true,
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            isDataLoaded: false,
            isDataLastYearLoaded: false
        }
    },
    watch: {
        rangePicker(dateString) {
            if (dateString.includes("to")) {
                const dateArray = dateString.split(" to ");
                if (dateArray.length === 2) {
                    const startDate = dateArray[0];
                    const endDate = dateArray[1];
                    this.startDate = startDate;
                    this.endDate = endDate;
                    this.loadData();
                } else {
                    console.log("Invalid date format.");
                }
            } else {
                console.log("Substring 'to' not found in the string.");
            }
        }
    },
    methods: {
        async loadData() {
            this.isDataLoaded = false;
            this.columnChartNumbers.series[0].data = [];
            this.columnChartAmount.series[0].data = [];
            this.columnChartNumbers.chartOptions.xaxis.categories = [];
            this.columnChartAmount.chartOptions.xaxis.categories = [];
            this.showLoading = true;
            this.loadDataLastYear();
            let response = await this.$http.post("order_header/findWhere", this.generateDateFilters()).catch(() => {
                this.showLoading = false;
            });
            let Top10ClientsByOrders = this.getTop10ClientsByOrders(response.data);
            this.pieChartNumbers.series = Top10ClientsByOrders.data;
            this.pieChartNumbers.labels = Top10ClientsByOrders.labels;
            let Top10ClientsByAmount = this.getTop10ClientsByAmount(response.data);
            this.pieChartAmount.series = Top10ClientsByAmount.data;
            this.pieChartAmount.labels = Top10ClientsByAmount.labels;
            this.isDataLoaded = true;
            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);
            // Calculate the time difference in milliseconds
            const timeDifference = endDate - startDate;
            // Calculate the difference in days
            const differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            if (differenceInDays < 50) {
                this.culumnChartNumbersTitle = "Variation Quotidienne des Ventes Par Nombre de Commandes";
                this.columnChartAmountTitle = "Variation Quotidienne des Ventes Par Chiffre D'affaires";
                const output = this.processOrdersGroupedByDays(response.data);
                this.columnChartNumbers.chartOptions.xaxis.categories = output.dataGroupedByDays.map(obj => obj.day);
                this.columnChartAmount.chartOptions.xaxis.categories = this.columnChartNumbers.chartOptions.xaxis.categories;
                this.columnChartNumbers.series[0].data = output.dataGroupedByDays.map(obj => obj.orderCount);
                this.columnChartAmount.series[0].data = output.dataGroupedByDays.map(obj => obj.sumAmount);
                this.showDataKeys += 1;
                if (this.isDataLastYearLoaded)
                    this.showLoading = false;
            } else {
                this.culumnChartNumbersTitle = "Variation Mensuelle des Ventes Par Nombre de Commandes";
                this.columnChartAmountTitle = "Variation Mensuelle des Ventes Par Chiffre D'affaires";
                const output = this.processOrdersGroupedByMonth(response.data);
                this.columnChartNumbers.chartOptions.xaxis.categories = output.distinctMonths;
                this.columnChartAmount.chartOptions.xaxis.categories = output.distinctMonths;
                this.columnChartNumbers.series[0].data = output.dataGroupedByMonths.map(obj => obj.orderCount);
                this.columnChartAmount.series[0].data = output.dataGroupedByMonths.map(obj => obj.sumAmount);
                this.showDataKeys += 1;
                if (this.isDataLastYearLoaded)
                    this.showLoading = false;
            }
        },
        getTop10ClientsByOrders(inputList) {
            const clientOrders = {};

            // Step 1: Count the number of orders for each client
            inputList.forEach(item => {
                const customerName = item.Bill_to_Name;

                if (!clientOrders[customerName]) {
                    clientOrders[customerName] = 0;
                }
                clientOrders[customerName]++;
            });

            // Step 2: Sort clients by the number of orders in descending order
            const sortedClients = Object.keys(clientOrders).sort((a, b) => clientOrders[b] - clientOrders[a]);

            // Step 3: Get the top 10 clients
            const top10Clients = sortedClients.slice(0, 10);

            // Step 4: Prepare the output dataset
            const labels = top10Clients;
            const data = top10Clients.map(client => clientOrders[client]);

            return {
                labels,
                data,
            };
        },
        getTop10ClientsByAmount(inputList) {
            const clientAmounts = {};

            // Step 1: Calculate the total amount spent by each client
            inputList.forEach(item => {
                const customerName = item.Bill_to_Name;
                const amount = parseFloat(item.Amount_Including_VAT);

                if (!clientAmounts[customerName]) {
                    clientAmounts[customerName] = 0;
                }
                clientAmounts[customerName] += amount;
            });

            // Step 2: Sort clients by the total amount spent in descending order
            const sortedClients = Object.keys(clientAmounts).sort((a, b) => clientAmounts[b] - clientAmounts[a]);

            // Step 3: Get the top 10 clients
            const top10Clients = sortedClients.slice(0, 10);

            // Step 4: Prepare the output dataset
            const labels = top10Clients;
            const data = top10Clients.map(client => clientAmounts[client]);

            return {
                labels,
                data,
            };
        },
        async loadDataLastYear() {
            this.isDataLastYearLoaded = false;
            let response = await this.$http.post("order_header/findWhere", this.generateDateFiltersLastYear()).catch(() => {
                this.showLoading = false;
            });
            this.isDataLastYearLoaded = true;
            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);
            // Calculate the time difference in milliseconds
            const timeDifference = endDate - startDate;
            // Calculate the difference in days
            const differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            if (differenceInDays < 50) {
                const output = this.processOrdersGroupedByDays(response.data);
                this.columnChartNumbers.chartOptions.xaxis.categories = output.dataGroupedByDays.map(obj => obj.day);
                this.columnChartAmount.chartOptions.xaxis.categories = this.columnChartNumbers.chartOptions.xaxis.categories;
                this.columnChartNumbers.series[1].data = output.dataGroupedByDays.map(obj => obj.orderCount);
                this.columnChartAmount.series[1].data = output.dataGroupedByDays.map(obj => obj.sumAmount);
                this.showDataKeys += 1;
                if (this.isDataLoaded)
                    this.showLoading = false;
            } else {
                const output = this.processOrdersGroupedByMonth(response.data);
                this.columnChartNumbers.chartOptions.xaxis.categories = output.distinctMonths;
                this.columnChartAmount.chartOptions.xaxis.categories = output.distinctMonths;
                this.columnChartNumbers.series[1].data = output.dataGroupedByMonths.map(obj => obj.orderCount);
                this.columnChartAmount.series[1].data = output.dataGroupedByMonths.map(obj => obj.sumAmount);
                this.showDataKeys += 1;
                if (this.isDataLoaded)
                    this.showLoading = false;
            }
        },
        subtractYearFromDate(dateString) {
            const originalDate = new Date(dateString);
            // Subtract one year from the date
            originalDate.setFullYear(originalDate.getFullYear() - 1);
            const year = originalDate.getFullYear();
            const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
            const day = originalDate.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        processOrdersGroupedByDays(inputList) {
            const orders = [];

            // Step 1: Extract and parse the data
            inputList.forEach(item => {
                const postingDate = item.Posting_Date;
                const amount = parseFloat(item.Amount_Including_VAT);
                const orderNo = item.No;

                orders.push({ postingDate, amount, orderNo });
            });

            // Step 2: Group data by day and calculate sum of amount per day
            const groupedByDay = {};
            orders.forEach(order => {
                const { postingDate, amount } = order;
                if (!groupedByDay[postingDate]) {
                    groupedByDay[postingDate] = { sumAmount: 0, orderCount: 0 };
                }
                groupedByDay[postingDate].sumAmount += amount;
                groupedByDay[postingDate].orderCount++;
            });

            // Step 3: Prepare the final output with "DD Month" format
            const dataGroupedByDays = Object.keys(groupedByDay).map(day => {
                const formattedDay = new Date(day).toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: 'short'
                });

                return {
                    day: formattedDay,
                    sumAmount: groupedByDay[day].sumAmount,
                    orderCount: groupedByDay[day].orderCount
                };
            });

            return {
                dataGroupedByDays,
            };
        },
        processOrdersGroupedByMonth(inputList) {
            const orders = [];

            // Step 1: Extract and parse the data
            inputList.forEach(item => {
                const postingDate = new Date(item.Posting_Date);
                const amount = parseFloat(item.Amount_Including_VAT);
                const orderNo = item.No;

                orders.push({ postingDate, amount, orderNo });
            });

            // Step 2: Group data by month and calculate sum of amount and order count per month
            const groupedByMonth = {};
            orders.forEach(order => {
                const { postingDate, amount } = order;
                const yearMonth = postingDate.toLocaleString('default', { year: 'numeric', month: 'long' });

                if (!groupedByMonth[yearMonth]) {
                    groupedByMonth[yearMonth] = { sumAmount: 0, orderCount: 0 };
                }
                groupedByMonth[yearMonth].sumAmount += amount;
                groupedByMonth[yearMonth].orderCount++;
            });

            // Step 3: Prepare the final output
            const distinctMonths = Object.keys(groupedByMonth);
            const dataGroupedByMonths = [];

            distinctMonths.forEach(month => {
                dataGroupedByMonths.push({
                    month,
                    sumAmount: groupedByMonth[month].sumAmount,
                    orderCount: groupedByMonth[month].orderCount
                });
            });

            return {
                distinctMonths,
                dataGroupedByMonths,
            };
        },
        getFirstAndLastDay(year, month) {
            // Ensure month is in the range 1-12
            if (month < 1 || month > 12) {
                throw new Error("Month must be between 1 and 12.");
            }

            // Create a new date object for the first day of the month
            const firstDay = new Date(year, month - 1, 1);

            // Create a new date object for the last day of the next month, then subtract one day
            const lastDay = new Date(year, month, 0);

            // Format dates as "YYYY-MM-DD"
            const formattedFirstDay = `${firstDay.getFullYear()}-${(month + "").padStart(2, "0")}-01`;
            const formattedLastDay = `${lastDay.getFullYear()}-${(month + "").padStart(2, "0")}-${lastDay.getDate()}`;

            return {
                firstDay: formattedFirstDay,
                lastDay: formattedLastDay
            };
        },
        getCurrentAndPastDay(days) {
            const currentDate = new Date();
            const pastDate = new Date();
            pastDate.setDate(pastDate.getDate() - days);

            const formattedCurrentDay = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1 + "").padStart(2, "0")}-${currentDate.getDate()}`;
            const formattedPastDay = `${pastDate.getFullYear()}-${(pastDate.getMonth() + 1 + "").padStart(2, "0")}-${pastDate.getDate()}`;

            return {
                currentDay: formattedCurrentDay,
                pastDay: formattedPastDay,
            };
        },
        generateDateFilters() {
            const filters = [
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];

            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);

            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);

            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];

            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate;
            filters[1].value = formattedEndDate;

            return filters;
        },
        generateDateFiltersLastYear() {
            const filters = [
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];

            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);

            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);

            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];

            // Set adjusted date values in the filter objects
            filters[0].value = this.subtractYearFromDate(formattedStartDate);
            filters[1].value = this.subtractYearFromDate(formattedEndDate);

            return filters;
        },
    },
    created() {
        let data = this.getCurrentAndPastDay(20);
        this.rangePicker[0] = data.pastDay;
        this.rangePicker[1] = data.currentDay;
        this.startDate = data.pastDay;
        this.endDate = data.currentDay;
        this.loadData();
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>